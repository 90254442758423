export const START_STORY = "Start Story";
export const VIEW_STORY = "View Story";
export const MARK_COMPLETE_STORY = "Mark Complete Story";
export const VIEW_QUES = "Viewquestion";
export const SUBMIT_QUES = "Submitquestion";
export const START_ACTIVITY = "Start Activity";
export const COMPLETE_ACTIVITY = "Mark complete activity";
export const CLOSE_ACTIVITY = "Close Activity";
export const LIKE_STORY = "Like Story";
export const CLOSE_STORY = "Skip Story";
export const NAVIGATION_TO = "Navigation to";
export const SWITCH_CHILD = "Switch_Child";
export const START_STORY_AGAIN = "Start Story Again";
export const VIEW_COLLECTION = "View Collection";
export const VIEW_ACTIVITY_PACK = "View Activity Pack";
export const APP_LAUNCHED = "app_launched";
export const VIEW_PREVIOUS_CLASS_LIST = "view_previous_class_list";
export const VIEW_UPCOMING_CLASS_LIST = "view_upcoming_class_list";
export const VIEW_UPCOMING_CLASS = "view_upcoming_class";
export const VIEW_PREVIOUS_CLASS = "view_previous_class";
export const JOIN_UPCOMING_CLASS = "join_upcoming_class";
export const VIEW_UPDATES = "view_updates";
export const VIEW_HOMEWORKS = "view_homeworks";
export const VIEW_BATCHMATES = "view_batchmates";
export const EDIT_PROFILE = "edit_profile";
export const FLIVE_RENEWAL = "flive_renewal";
export const ONBOARDING_GETSTARTED = "onboarding_getstarted";
export const ONBOARDING_GETSTARTED_SCHOOLCODE =
  "onboarding_getstarted_schoolcode";
export const ONBOARDING_PHONE_REGISTER = "onboarding_phonenumber_register";
export const REGISTRATION_OTP = "Registration (Submit OTP)";
export const ONBOARDING_SELECT_GRADE = "onboarding_selectgrade";
export const ONBOARDING_ADD_CHILD = "Add_child_onboarding";
export const LOGIN_ADD_CHILD = "Add child login";
export const ONBOARDING_SELECT_SECTION = "onboarding_selectsection";
export const ONBOARDING_SCHOOLCODE = "SchoolCode";
export const READING_CHALLNEGE_CARD = "100DaysOfReading_banner";
export const READING_CHALLENGE_BANNER_CLICK =
  "100DaysOfReading_start_challenge";
export const FREADOM_COMMUNITY = "freadom_community";
export const FLUENCY_TEST_BANNER = "fluency_test_banner";
export const FLUENCY_TEST_START = "fluency_test_start";
export const FLUENCY_TEST_START_RECORDING = "fluency_test_start_recording";
export const FLUENCY_TEST_FINAL_SUBMISSION = "fluency_test_final_submission";
export const FLUENCY_TEST_VIEW_REPORTS = "fluency_test_view_reports";
export const START_NEWS = "Start News";
export const MARK_COMPLETE_NEWS = "Mark Complete News";
export const NEWS_ID_FOR_NEWS = "News ID";
export const NEWS_NAME_FOR_NEWS = "News name";

// source page id
export const SOURCE_PAGE_READ = "Read page";
export const SOURCE_PAGE_HOME = "Home page";
export const SOURCE_PAGE_COLLECTION = "Collection page";
export const SOURCE_PAGE_ACTIVITIES = "Activities page";
export const SOURCE_PAGE_READING_CHALLENGE = "Reading challenge page";
export const SOURCE_PAGE_PROFILE = "Profile page";
export const SOURCE_STORY_COMPLETE = "Story complete page";
export const SOURCE_STORY_DETAIL = "Story detail page";
export const SOURCE_STORY_COMPLETED = "Story Completed page";
export const SOURCE_STORY_READING = "Story Reading page";
export const SOURCE_MAGIC_STORY = "Magic Story page";
export const SOURCE_COLLECTION_DETAIL = "Collection detail page";
export const SOURCE_PACK_DETAIL = "Activity pack detail page";
export const SOURCE_SEARCH_PAGE = "Search page";
export const SOURCE_STREAK_PAGE = "Streak page";
export const SOURCE_NEWS_FREAD_PAGE = "News fread page";
export const SOURCE_LEADER_BOARD_PAGE = "LeaderBoard page";
export const SOURCE_SWITCH_CHILD_PAGE = "Switch child page";
export const SOURCE_FEED_PAGE = "Home Feed page";
export const SOURCE_GUIDED_PATH_PAGE = "Guided Path page";
export const SOURCE_PROFILE_GROWTH = "Profile_Growth";
export const SOURCE_PROFILE_PERFORMANCE = "Profile_Performance";
export const SOURCE_PROFILE_INFO = "Profile_Info";
export const SOURCE_PROFILE_PAYMENT = "Profile_Payment";
export const SOURCE_FROM_DEEP_LINK = "deep_link";
export const SOURCE_FLIVE_INTRO = "Flive Intro Page";
export const SOURCE_PLAY_PAGE = "Play";
export const SOURCE_SIGN_UP_PAGE = "Signup page";
export const SOURCE_LOGIN_PAGE = "Login page";
export const SOURCE_SCHOOL_CODE_ONBOARDING_PAGE = "School Code Onboarding";
export const SOURCE_STUDENT_DASHBOARD_PAGE = "student dashboard page";
export const SOURCE_PAGE_QUIZ = "Quiz Page";
export const SOURCE_PAGE_PASSAGE = "Speech test passage Page";


// activity content
export const SOURCE_INDIVIDUAL_ACTIVITY = "Individual activities";
export const SOURCE_ONGOING_ACTIVITY_PACKS = "Ongoing Activity Packs";
export const SOURCE_LOCKED_ACTIVITY_PACKS = "Locked Activity Packs";
export const SOURCE_UNLOCKED_ACTIVITY_PACKS = "Unlocked Activity Packs";
export const SOURCE_COMPLETED_ACTIVITY_PACKS = "Completed Activity Packs";

export const STORY_TYPE = "story_type";
export const STORY_NAME = "story_name";
export const SECTION_ID = "section_id";
export const STORY_LIKE = "story_like";
export const STORY_ID = "story_id";
export const SESSION_ID = "session_id";
export const SOURCE_PAGE_ID = "source_page_id";
export const PARTNER_SOURCE = "partner_source";
export const TIMESTAMP = "timestamp";
export const VIEW_ID = "view_id";
export const SKILL_TAGS = "Skill_tags";
export const CHILD_ID = "child_id";
export const CHILD_LEVEL = "child_level";
export const SCHOOL_CODE_FOR_STORY = "Schoolcode";
export const SOURCE = "Source";

export const CONTENT_ID = "contentId";
export const CONTENT_TYPE = "Content Type";
export const ACTIVITY_PACK_ID = "ActivityPackID";
export const ACTIVITY_PACK_NAME = "Activity pack name";
export const ACTIVITY_NAME = "Activity name";
export const WOW_CODE = "WoWcode";
export const CONTENT_NAME = "content_name";
export const NEWS_NAME = "news_name";
export const NEWS_ID = "news_id";
export const QUESTION_ID = "questionId";

// export const ACTIVITY_ID = "activity_id"; // not in use
export const ACTIVITY_ID = "Activity ID"; // not in use

export const OPTION_SELECTED = "OptionSelected";
export const OPTION_SELECTED2 = "option_selected";
export const OPTION_RESULT = "Result";

export const SKIPPED_STORIES_COUNT = "skipped_stories_count"; // nd
export const SKIPPED_STORIES = "skipped_stories"; // nd

export const FROM = "From";
export const TO = "To";
export const COLLECTION_ID = "collection_id";
export const COLLECTION_NAME = "collection_name";
export const PLATFORM = "platform";
export const PHONE_NUMBER = "phonenumber";
export const EMAIL = "Email";
export const APP_VERSION = "app_version";
export const DEVICE_ID = "device_id";

export const SESSION_DATE = "session date";
export const SESSION_TIME = "session time";
export const SESSION_TITLE = "session title";
export const BUTTON_LOCATION = "button location";
export const SESSION_CONCLUDED = "session concluded";
export const SESSION_START_TIME = "session_start_time";
export const SESSION_END_TIME = "session_end_time";

export const MSG_EMAIL = "MSG-email";
export const MSG_PUSH = "MSG-push";
export const MSG_SMS = "MSG-sms";
export const MSG_WHATSAPP = "MSG-whatsapp";
export const NAME = "Name";
export const IDENTITY = "Identity";
export const CT_CHILD_NAME = "child_name";
export const CT_CHILD_ID = "child_id";
export const CHILD_SCHOOL = "child_school";
export const CHILD_GRADE = "child_grade";
export const CHILD_GRADE_ID = "child_grade_ID";
export const CHILD_PROFILE_IMAGE = "child_profile_image";
export const SUBSCRIPTION_STATUS = "subscription_status";
export const ASSESSMENT_STATUS = "assessment_status";
export const SURVEY_TAKEN = "survey_taken";
export const COUNTRY = "country";
export const REGISTRATION_DATE = "registration_date";
export const CHILD_FIRST_ACTIVE = "child_first_active_at";
export const SUBSCRIPTION_DATE = "subscription_date";
export const TRIAL_DAYS = "trial_days";
export const PHONE = "Phone";
export const PARENT_ID = "parent_ID";
export const PARENT_NAME = "parent_name";
export const PARENT_PHONE = "parent_phone";
export const PARENT_EMAIL = "parent_email";
export const PARENT_FIRST_ACTIVE = "parent_first_active_at";
export const GRADE = "grade";
export const SCHOOL_SECTION = "school_section";
export const SCHOOL_CODE = "school_code";
export const SCHOOL_TAG = "school_tag";
export const STATUS = "Status";

// FREAD-1323
//Search
export const SEARCH = "Search";
export const SEARCH_TEXT = "searchtext";
// FREAD-1323

//FREAD-1342
export const FLIVE_JOIN_BUTTON = "flive_join_button";
export const FLIVE_RENEW_BUTTON = "flive_renew_button";
export const FLIVE_VIEW_ALL_BATCH_BUTTON = "flive_view_all_batchmates";
export const FLIVE_UPCOMING_CLASSES_JOIN_BUTTON =
  "flive_upcomingclasses_join_button";
export const FLIVE_UPCOMING_CLASSES_HEADER_TAB =
  "flive_upcomingclasses_header_tab";
export const FLIVE_PREVIOUS_CLASSES_HEADER_TAB =
  "flive_previousclasses_header_tab";
export const FLIVE_PREVIOUS_CLASSES_CARD = "flive_previousclasses_card";
export const FLIVE_EDIT_PROFILE = "flive_edit_profile";
export const FLIVE_HOMEWORK_VIEW_ALL = "flive_homework_viewall";
export const FLIVE_PREVIOUS_CLASSES_HOMEWORK = "flive_previousclasses_homework";
export const FLIVE_SHOW_RESULT = "flive_show_results";
export const FLIVE_GO_TO_HOMEWORK = "flive_go_to_homework";
export const FLIVE_TEST_TAB = "flive_test_tab";
//FREAD-1342

//FREAD-1369
export const QUIZ_NAVBAR_PLAY = "quiz_nav_bar_play";
export const VIEW_QUIZ = "View Quiz";
export const QUIZ_PLAY_BUTTON = "quiz_play_button";
export const QUIZ_BG_MUSIC = "quiz_bg_music";
export const QUIZ_SOUND_EFFECTS = "quiz_sound_effects";
export const QUIZ_DRAG_ACTION = "quiz_drag_action";
export const QUIZ_NEXT_BUTTON = "quiz_next_button";
export const QUIZ_SKIP_BUTTON = "quiz_skip_button";
export const QUIZ_EXIT_BUTTON = "quiz_exit_button";
export const QUIZ_SPECIAL_OFFER_BANNER = "quiz_special_offer_banner";
export const QUIZ_CLOSE_BUTTON_POPUP = "quiz_close_button_popup";
export const QUIZ_BACK_BUTTON_POPUP = "quiz_back_button_popup";
export const QUIZ_RESUME_QUIZ_BUTTON = "quiz_resume_quiz_button";
export const QUIZ_EXIT_BUTTON_ARE_YOU_SURE = "quiz_exit_button_areyousure";
export const QUIZ_TITLE = "title";
export const QUIZ_FREADOS_EARNED = "freados_earned";

//FREAD-1369

//Guided Path

export const GUIDED_PATH_UNIT = "guided_path_unit";
export const UNIT_ID = "unit_id";
export const UNIT_NAME = "unit_name";
export const UNIT_POSITION = "unit_position";
export const TASK_ID = "task_id";
export const TASK_NAME = "task_name";
export const TASK_TYPE = "task_type";
export const GUIDED_PATH_TASK = "guided_path_task";
export const GUIDED_PATH_TASK_COMPLETION = "guided_path_task_completion";
export const GUIDED_PATH_UNIT_COMPLETION = "guided_path_unit_completion";

//READ ALONG AND READ TO ME

export const READ_TO_ME_PAGE = "Read To Me";
export const READ_TO_ME_PLAY_BUTTON = "read_to_me_play_btn";
export const READ_TO_ME_STOP_BUTTON = "read_to_me_stop_btn";
export const READ_TO_ME_EXIT_BUTTON = "read_to_me_exit_btn";
export const READ_TO_ME_STORY_NAME = "story_name";
export const READ_TO_ME_STORY_URL = "story_url";
