import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import "./assets/stylesheet/global.scss";
// import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from "./routes";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as EVENT from "./utils/eventKeys";
import { storage as LocalStorage } from "services/config/storage";
import { Analytics } from "./services/analytics";
import version from "../package.json";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "./utils/commonUtils";
const uuid = uuidv4();

let isPageLoaded = false;

function handleTabClose(event) {
  const sessionObj = {
    started_at: new Date(JSON.parse(localStorage.getItem("sessionStart"))),
    ended_at: new Date(),
  };

  if (isPageLoaded) {
    try {
      Analytics.onlogOut({
        start: sessionObj.started_at,
        end: sessionObj.ended_at,
      });
    } catch (error) {
      console.error("Failed to log tab close event:", error);
    }
    // Optional: If you want a confirmation dialog on tab close (not supported in all browsers)
    event.returnValue = ""; // Needed for Chrome and some other browsers
  }
}

function generateDeviceId() {
  const navigatorInfo = window.navigator;
  const screenInfo = window.screen;
  const uid =
    navigatorInfo.mimeTypes.length +
    navigatorInfo.userAgent.replace(/\D+/g, "") +
    navigatorInfo.plugins.length +
    screenInfo.height +
    screenInfo.width +
    screenInfo.pixelDepth;
  return uid;
}

function mainRender() {

  let payload = {};
  payload[EVENT.DEVICE_ID] = "";
  if (LocalStorage.fetch.defaultChild()?.id) Analytics.onLogin();
  Analytics.logEvents(EVENT.APP_LAUNCHED, payload);

  // Set the flag when the page is loaded
  window.addEventListener("load", () => {
    isPageLoaded = true;
  });

  window.addEventListener("beforeunload", handleTabClose);

  window.addEventListener("unload", () => {
    isPageLoaded = false; // Reset the flag if needed
  });

  let device_id = generateDeviceId();
  localStorage.setItem("device_id", device_id);

  ReactDOM.render(
    <Provider store={store}>
      <AppRouter />
      <ToastContainer autoClose={2300} bodyClassName={"toast-body"} />
    </Provider>,
    document.getElementById("root")
  );
}

// Adding the rended to setTimeout with zero millisec to tackle FOUC
if (process.env.NODE_ENV !== "production") {
  LocalStorage.set.sessionData(uuid);
  // Workaround for https://github.com/facebook/create-react-app/issues/6399
  // until it gets fixed upstream
  setTimeout(() => {
    mainRender();
  }, 0);
} else {
  LocalStorage.set.sessionData(uuid);
  mainRender();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
